<template lang="pug">
div
  div.note(v-for='note in notes')
    p {{ note.content }}
    p.note-info {{ note.userName }} • {{ note.date | datetime }}
</template>

<script>
export default {
  props: {
    notes: { type: Array, required: true }
  }
}
</script>

<style lang="scss" scoped>
.note {
  padding: 2px 0;
  font-size: 0.9em;
  border-bottom: solid 1px #dadada;

  &:hover {
    background: #ececec;
  }
}

.note-info {
  font-size: 0.8em;
  opacity: 0.7;
}
</style>
