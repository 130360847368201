let componentInstance = null

export const openAllNotesModal = async (notes, parent) => {
  if (!componentInstance) {
    const component = await import(/* webpackChunkName: "all-notes-modal" */ './AllNotesModal.vue')
    componentInstance = component.default
  }

  const modal = parent.$buefy.modal
  const props = {
    notes,
    isModal: true
  }

  modal.open({
    component: componentInstance,
    parent,
    hasModalCard: true,
    trapFocus: true,
    canCancel: ['escape', 'x'],
    props
  })
}
