<template lang="pug">
.container
  page-title(:title='`Información de ${device.name || "..."}`')
    b-button.button.is-primary(@click='save' :loading='isSaving') Guardar
  b-loading(:is-full-page='false' v-model='isLoading')
  .columns
    .column
      .form-section.px-3.py-3
        h3.mb-4 Información general
        text-field(label='Nombre' placeholder='Ejm: Placa' :maxlength='30' required v-model='device.name')
        text-field(label='Odometro actual' placeholder='Valor actual de odómetro' v-model.number='device.odometer')
        text-field(label='Kilómetros por galón' placeholder='Para calcular el consumo' v-model.number='device.kmPerGallon')
        text-field(label='Agrupador' placeholder='Los equipos podrán agruparse por este valor' v-model='device.group')
        text-field(label='IMEI o ID' required v-model='device.id' readonly)
        //- .flex.mb-4
          label.mr-2 Línea:
          table-picker(
            :data='lines'
            :data-async='getLines'
            :isLoading='isLoadingLines'
            :name='device.lineNumber'
            v-model='device.lineNumber'
            :link-to='{ name: "line", params: { lineNumber: device.lineNumber } }'
            :hide-link='!device.lineId'
            only-ids
          )
        select-field(label='Cliente' :items='clients' required v-model.number='device.clientId' v-if='permissions.devicesEditSensitive')
    .column
      .form-section.px-3.py-3.mb-3(v-if='permissions.devicesEditSensitive')
        h3.mb-4 Comandos habilitados del equipo
        b-field(v-for='commandType in commandTypes' :key='commandType')
          b-checkbox(v-model="types[commandType]") {{ commandTypeDescriptions[commandType] }}
      .form-section.px-3.py-3.mb-3
        h3.mb-4 Ícono en el mapa
        div
          button.device-icon(
            :class="{ current: icon.key === device.image }"
            type='button'
            v-for='icon in markerIcons'
            @click='device.image = icon.key'
          )
            svg(v-if='icon.icon.colorIcon' width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg")
              path(d="M12.9521 0L26 32L12.9522 22.6667L0 32L12.9521 0Z" :fill="icon.icon.colorIcon")
            img.device-icon-img(v-else :src='icon.icon.url')
      .form-section.px-3.py-3
        h3.mb-4 Notas
        notes-widget(type='EQUIPO' :type-id='device.id' v-if='device && device.id')
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TablePicker from '@/components/shared/TablePicker.vue'
import NotesWidget from '@/components/notes/NotesWidget.vue'
import devicesService from '@/services/devices.service'
import { commandTypeEnum, commandTypeDescriptions } from '@/constants/enums'
import toastService from '@/services/toast.service'
import { markerIcons } from './constants'

export default {
  components: { TablePicker, NotesWidget },
  data () {
    return {
      markerIcons: Object.keys(markerIcons).map(key => ({ key, icon: markerIcons[key] })),
      isSaving: false,
      isLoadingLines: false,
      isLoading: false,
      commandTypes: [
        commandTypeEnum.TurnOffEngine,
        commandTypeEnum.TurnOnEngine,
        commandTypeEnum.OpenDoors
      ],
      commandTypeDescriptions,
      lines: [],
      device: {},
      types: {}
    }
  },
  async created () {
    if (this.permissions.devicesEditSensitive) {
      await this.getClients()
    }
    this.getDevice()
  },
  computed: {
    ...mapState({
      clients: state => state.clients.list
    }),
    permissions () {
      return this.$store.getters['auth/permissions']
    }
  },
  methods: {
    ...mapActions({
      getClients: 'clients/getClients'
    }),
    async getDevice () {
      this.isLoading = true
      const [deviceResult, metadataResult] = await Promise.all([
        devicesService.getOne(this.$route.params.id),
        devicesService.getEnabledCommands(this.$route.params.id)
      ])
      if (deviceResult && metadataResult) {
        this.device = deviceResult
        const enabledCommands = metadataResult.deviceEnabledCommands || []
        enabledCommands.forEach(command => {
          this.types[`${command}`] = true
        })
        // this.device.lineNumber = '123234'
      }
      this.isLoading = false
    },
    async getLines () {
      this.isLoadingLines = true
      return new Promise(resolve => {
        setTimeout(() => {
          this.lines = [{ id: 1, lineNumber: '1234' }]
          resolve(this.lines)
          this.isLoadingLines = false
        }, 1000)
      })
    },
    async save () {
      this.isSaving = true
      const deviceEnabledCommands = Object.keys(this.types).filter(type => this.types[type]).map(type => type)
      const result = await devicesService.update({
        id: this.device.id,
        device: this.device,
        metadata: { deviceEnabledCommands }
      })
      if (result) {
        toastService.show('Datos del equipo guardados')
      }
      this.isSaving = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/sizes';

.form-section {
  border: solid 1px #dadada;
}

.device-icon {
  background: none;
  border: none;
  padding: 4px;
  border: solid 1px transparent;

  &:hover {
    cursor: pointer;
    background-color: #dadada;
  }

  &.current {
    background: #c5c5c5;
    border-color:#7f7f7f;
  }
}

.device-icon-img {
  height: 32px;
}

</style>
