<template lang="pug">
.notes-widget
  form.new-note-input(@submit.prevent='createNote')
    text-field.m-0(placeholder='Agregue una nueva nota aquí' :maxlength='1000' v-model='newNote.content' :disable='saving')
    b-button.ml-2(v-if='newNote.content' :loading='saving' native-type='submit') Guardar
  p(v-if='loading') Cargando...
  .no-data-found(v-else-if='notes.length === 0')
  div(v-else)
    notes-list(:notes='latest')
    .mt-2
      a(@click='seeAllNotes' v-if='latest.length < notes.length') Ver todas las notas
</template>

<script>
import notesService from '@/services/notes.service'
import toastService from '@/services/toast.service'
import { openAllNotesModal } from './all-notes.modal'
import NotesList from './NotesList.vue'

export default {
  components: { NotesList },
  props: {
    type: { type: String, required: true },
    typeId: { type: [String, Number], required: true }
  },
  data () {
    return {
      loading: false,
      saving: false,
      notes: [],
      newNote: {
        type: this.type,
        typeId: `${this.typeId}`,
        content: ''
      }
    }
  },
  async created () {
    this.loading = true
    const { type, typeId } = this
    const result = await notesService.getMyNotes(type, `${typeId}`)
    if (result) {
      this.notes = result
      this.$emit('loaded', this.notes)
    }
    this.loading = false
  },
  computed: {
    latest () {
      return this.notes.slice(0, 3)
    }
  },
  methods: {
    seeAllNotes () {
      openAllNotesModal(this.notes, this)
    },
    async createNote () {
      this.saving = true
      const result = await notesService.save(this.newNote)
      if (result) {
        this.notes.unshift(result)
        this.newNote.content = ''
        toastService.show('Nota agregada')
        this.$emit('loaded', this.notes)
      }
      this.saving = false
    }
  }
}
</script>

<style lang="scss" scoped>
.new-note-input {
  display: flex;
  justify-content: space-between;

  .field {
    flex: 1;
  }
}
</style>
